/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import cssVars from 'css-vars-ponyfill';

import App from './App';
import { RouterWrapper } from 'routes';

import './assets/styles/sso.scss';

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_CUSTOM_NODE_ENV === 'production') {
  require('./segment');
  require('./googleAnalytics');
  require('./intercom');
}

cssVars({
  rootElement: document.head,
  onlyLegacy: false,
  preserveVars: true,
  watch: true,
});

ReactDOM.render((
  <RouterWrapper>
    <App />
  </RouterWrapper>
), document.getElementById('root'));
