const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

const startYear = 1900;
const currentYear = new Date().getFullYear();

const days30 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
const days31 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
const days28 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];

const days = {
  'JAN': days31,
  'FEB': days28,
  'MAR': days31,
  'APR': days30,
  'MAY': days31,
  'JUN': days30,
  'JUL': days31,
  'AUG': days31,
  'SEP': days30,
  'OCT': days31,
  'NOV': days30,
  'DEC': days31,
};

const yearsList = [];
for (let year = startYear; year <= currentYear; year++) {
  yearsList.push(year);
}

export {
  days,
  months,
  yearsList,
  startYear,
  currentYear,
};
